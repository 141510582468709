import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Horizon } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { ContentNode, EntityProvider } from '@shapeable/ui';
const cls = classNames('horizon-card');

// -------- Types -------->

export type HorizonCardProps = Classable & HasChildren & { 
  entity?: Horizon;
};

export const HorizonCardDefaultProps: Omit<HorizonCardProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {
  _color?: string;
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: block;
    text-decoration: none;
    color: ${theme.COLOR('strong')};

    ${({ _color }: ContainerProps) => _color && css`
      color: ${_color};
    `}
  `,
});

const BodyStyles = breakpoints({
  base: css`
  `,
});


const TitleStyles = breakpoints({
  base: css`
    margin: ${theme.UNIT(3)} 0 ${theme.UNIT(4)} 0;
    font-size: ${theme.FONT_SIZE(15)};
    font-weight: 500;
    font-size: ${21/16}em;
  `,
});


const SubtitleStyles = breakpoints({
  base: css`
    font-weight: 300;
    padding-left: ${theme.UNIT(1.5)};
  `,
});

const IntroStyles = breakpoints({
  base: css`
    margin: 0 0 ${theme.UNIT(4)} 0;
    font-size: ${theme.FONT_SIZE(15)};
    font-family: ${theme.FONT('serif')};
    font-weight: 400;
    font-size: ${18/16}em;
    padding-right: ${theme.UNIT(6)};
  `,
});

const DescriptionStyles = breakpoints({
  base: css`
    color: ${theme.COLOR('strong')};
    transform: scale(1.0);
    font-weight: 300;
    line-height: 1.45em;
    margin-bottom: ${theme.UNIT(4)};
    padding-right: ${theme.UNIT(4)};
  `,
});

// -------- Components -------->

const My = {
  Container: styled.article<ContainerProps>`${ContainerStyles}`,
    Body: styled.div`${BodyStyles}`,
      Title: styled.h1`${TitleStyles}`,
        Subtitle: styled.b`${SubtitleStyles}`,
      Intro: styled.h2`${IntroStyles}`,
      Description: styled(ContentNode)`${DescriptionStyles}`,
};

export const HorizonCard: Shapeable.FC<HorizonCardProps> = (props) => {
  const { className, children, entity } = props;

  const { intro, description, type } = entity;

  const title = type.title;
  const subtitle = type.subtitle;

  const hasTitle = !!title && !!subtitle;
  const hasDescription = !!description.text;
  
  return (
    <EntityProvider value={entity}>
    <My.Container _color={entity?.color?.value} className={cls.name(className)}>
      <My.Body>
        {
          hasTitle &&
          <My.Title>
            {title}
            {subtitle && <My.Subtitle>{subtitle}</My.Subtitle>}
          </My.Title>
        }
        <My.Intro>{intro.text}</My.Intro>
        {
          hasDescription && 
          <My.Description entity={description} />
        }
      </My.Body>
    </My.Container>
    </EntityProvider>
  )
};

HorizonCard.defaultProps = HorizonCardDefaultProps;
HorizonCard.cls = cls;