import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { SubTopic } from '@shapeable/gesda-types';
import { ExplorerEntityDetailsLayout, SiteHeaderLayout, SliceLayoutBoundary, ExplorerEntityDetailsLayoutDefaultProps, ExplorerEntityDetailsLayoutProps, ComponentPropsProvider, BackButtonEntityProvider } from '@shapeable/ui';
import { ExplorerProvider } from '../providers/explorer-provider';
import { SubTopicTabsLayout } from './sub-topic-tabs-layout';
import { SubTopicViewLayout } from './sub-topic-view-layout';
import { useRadarMap } from '../../hooks/use-radar-map';
import { RadarChartMain } from '../elements/radar-chart-main';
import { RadarChart } from '../elements/radar-chart';
const cls = classNames('sub-topic-banner-layout');

// -------- Types -------->

// -------- Props -------->

export type SubTopicExplorerLayoutProps = ExplorerEntityDetailsLayoutProps & { 
  entity?: SubTopic;
};

export const SubTopicExplorerLayoutDefaultProps: Omit<SubTopicExplorerLayoutProps, 'entity'> = {
  ...ExplorerEntityDetailsLayoutDefaultProps,
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
});

const LayoutStyles = breakpoints({
  base: css`
  `,
});

const HeaderStyles = breakpoints({
  base: css`
    
  `,
});

const RadarChartStyles = breakpoints({
  base: css`
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Header: styled(SiteHeaderLayout)`${HeaderStyles}`,
    Layout: styled(ExplorerEntityDetailsLayout)`${LayoutStyles}`,
      RadarChart: styled(RadarChart)`${RadarChartStyles}`,

};

export const SubTopicExplorerLayout: Shapeable.FC<SubTopicExplorerLayoutProps> = (props) => {
  const { className, children, entity } = props;

  const { isShown } = useRadarMap();
  
  return (
    <ExplorerProvider>
    <BackButtonEntityProvider value={entity.topic}>
    <ComponentPropsProvider value={{
      EntityBanner: {
        // maxHeight: 600,
        variant: 'image',
        customAspectRatio: { base: 21 / 9 },
        showSubheader: true,
        hasBottomCorners: false,
        useBackgroundColor: false,
        entityValueProps: { showImage: false, showOutlineNumber: true, outlineBubble: { numberSize: 0.6, showBorder: false, showImage: false }},
      },
    }}>
    <My.Container>
      <My.Header variant="light" />
      <SliceLayoutBoundary boundary='none' >
      <My.Layout 
        entity={entity}
        className={cls.name(className)}
        asideIsRevealed={isShown}

        // main
        tabs={
          <SubTopicTabsLayout entity={entity} />
        }
        content={
          <SubTopicViewLayout entity={entity} />
        }
        dataVizMain={
          <RadarChartMain />
        }

        // aside
        dataViz={
          <My.RadarChart />
        }
        />
        </SliceLayoutBoundary>
    </My.Container>
    </ComponentPropsProvider>
    </BackButtonEntityProvider>
    </ExplorerProvider>
  )
};

SubTopicExplorerLayout.defaultProps = SubTopicExplorerLayoutDefaultProps;
SubTopicExplorerLayout.cls = cls;